
a.w-nav-link:hover {
    box-shadow:initial !important;
}
nav.nav-menu.w-nav-menu a:after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #60CFFF;
    position: absolute;
    bottom: 15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
nav.nav-menu.w-nav-menu a:hover:after , .w--current::after {
    width: 100% !important;
}
a.w-nav-link.w--current{
    color: #60CFFF !important;
}
a.w-nav-link {
    padding: 25px 0px!important;
    margin: 0px 20px;
}
.heading-3.heading-bnny {position: absolute;width: 100vw;}
.w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.container {
    display: contents !important;
}
.bunny-heading {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
}
.bunny-heading .heading-5 .bold-text-2 {
    font-size: 10vw;
    line-height: 123px;
}
.d-none {
    display: none;
}
.on-hover:hover .hover-show {
    display: block!important;
}
.on-hover:hover .on-hover-hide {
    display: none;
}
.purple .w-row {
    margin: 0px;
}
.transparent-heading strong {
    -webkit-text-stroke: 1px #fff;
    color: transparent;
    font-style: normal;
    font-weight: 900;
}
.left-top-1 {
    margin-top: 16%;
}
.right-top-1 {
    padding-top: 52%;
}
.left-top-2 {
    padding-top: 40%;
}
.right-top-2 {
    padding-top: 48%;
}
.left-top-3 {
    padding-top: 45%;
}
@media only screen and (min-width: 200px) and (max-width: 767px){
.road_position_one {
    display: none;
}
.road_position_three {
    display: none;
}
.road_position_two {
    display: none;
}
.road_position_two {
    left: 5%;
    bottom: 20%;
    position: absolute;
    max-width: 60px;
}
.road_position {
    right: 0;
    bottom: 0;
    position: relative;
    max-width: 200px;
    margin: 0 auto;
}
}
@media only screen and (min-width: 768px) and (max-width: 991px){
.road_position_one {
    left: 9%;
    top: 8%;
    position: absolute;
    max-width: 85px;
}
.road_position_three {
    right: 10%;
    top: 6%;
    position: absolute;
    max-width: 100px;
}
.road_position_two {
    left: 5%;
    bottom: 14%;
    position: absolute;
    max-width: 110px;
}
.road_position {
    right: 0;
    bottom: 88px;
    position: absolute;
    max-width: 300px;
}
}
@media only screen and (min-width: 992px) and (max-width: 1199px){
.road_position_one {
    max-width: 170px;
}
.road_position_three {
    max-width: 180px;
}
.road_position_two {
    max-width: 250px;
}
.road_position {
    right: 0;
    bottom: 73px;
    position: absolute;
    max-width: 400px;
}
}
@media only screen and (min-width: 1200px) and (max-width: 1367px){
.road_position_one {
    left: 9%;
    top: 8%;
    position: absolute;
    max-width: 200px;
}
.road_position_three {
    right: 10%;
    top: 6%;
    position: absolute;
    max-width: 200px;
}
.road_position_two {
    left: 5%;
    bottom: 20%;
    position: absolute;
    max-width: 280px;
}
.road_position {
    max-width: 600px;
}
}
@media only screen and (min-width: 1368px) and (max-width: 1550px){
.road_position {
    right: 0;
    bottom: 0;
    position: absolute;
    max-width: 650px;
}
}
.road_position_one {
    left: 9%;
    top: 8%;
    position: absolute;
  max-width: 200px;
}
.road_position_three {
    right: 10%;
    top: 6%;
    position: absolute;
  max-width: 200px;
}
.road_position_two {
    left: 5%;
    bottom: 15%;
    position: absolute;
  max-width: 200px;
}
h1.heading-11.part-heading {
    background-image: linear-gradient(to right,#a7d451,#bcc332,#ceb017,#de9c08,#ec8515);
    text-align: center;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.road_position {
    right: 0;
    bottom: 0;
    position: absolute;
    max-width: 28% !important;
}
.join-heading span {
    -webkit-text-stroke: 1px #fff;
    color: transparent;
    font-style: normal;
    font-weight: 900;
}
.home_community_flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    align-items: center;
}
.community_btn {
    font-size: 17px;
    background-image: linear-gradient(to right,#a7d451,#bcc332,#ceb017,#de9c08,#ec8515);
    border-radius: 50px;
    padding: 22px 44px;
    color: #fff;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}
.community_btn:hover {
background-image: linear-gradient(to right,#05f6ea,#20f1da,#33ebc9,#43e6b9,#51e0a8);
}
.community_btn2 {
    padding: 20px 44px;
    border: 2px solid #ccebff;
    background: transparent;
    color: #09f;
    border-radius: 50px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}
.community_btn2:hover {
    background-image: none;
    border: 2px solid transparent;
    background-color: #000;
}
.community_flex {
    display: flex;
}
a.button-3.community_btn.w-button {
    margin-right: 25px;
}
.text-block.faq-icon:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 22px;
    width: 3px;
    height: 24px;
    background: #60CFFF;
    box-shadow: 0px 4px 6px #000000;
    transform: translate(0,-50%);
    transition: all .4s ease-in-out;
}
.text-block.faq-icon:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 12px;
    width: 24px;
    height: 3px;
    background: #60CFFF;
    box-shadow: 0px 4px 6px #000000;
    transform: translate(0,-50%);
    transition: all .4s ease-in-out;
}
.text-block.faq-icon span {
    display: none;
}
.text-block.faq-icon {
    position: relative;
}
.acc_open .text-block.faq-icon:before {
    opacity: 0;
}
.acc_open .text-block.faq-icon:after {
    background: #fff !important;
}
.w-nav-link:hover {
    color: #60cfff;
}
.accordion-item {
    border-bottom: 1px solid #628290;
        margin-bottom: 25px;
}
.accordion-item-content {
    border-top: 1px solid #628290;
}
.accordion-item-trigger {
    padding-bottom: 20px;
    text-transform: uppercase;
}
.accordion-item-content p {
    padding-top: 25px;
    padding-bottom: 25px;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 28px;
    letter-spacing: 1px;
}
a.text-black.rounded-full.bg-white {
    height: 40px;
    width: 40px;
    display: flex;
    text-align: center;
    font-size: 25px;
    align-items: center;
    justify-content: center;
    margin: 10px;
}
.bg-white {
    background-color: #fff;
}
.rounded-full {
    border-radius: 50%;
}
.flex {
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;     /* NEW - Chrome */
  display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
 }
 .justify-center{
  justify-content: center;
 }
 .bg-white * {
    color: #000;
    fill: #000;
}
.community_flex p {
    margin: 0px;
}
.navigation.w-nav.menu-scroll {
    background: rgba(0,0,0,0.65);
}
@media only screen and (min-width:1501px){
  .bunny-heading .heading-5 .bold-text-2 {
    font-size: 9rem!important;
    line-height: 123px!important;
}
.on-hover-hide, .hover-show {
    width: 100%;
}
}
@media only screen and (min-width:768px) and (max-width:900px){
.left-top-1 , .left-top-2 , .left-top-3{
    padding-left: 0px;
}
.roadmap .w-col.w-col-2 {
    padding: 0px;
}
.right-top-1 , .right-top-2, .right-top-3 {
    padding-right: 0px;
}
img.road-size {
    min-height: 700px;
}
.left-top-3 {
    padding-top: 30%;
}
}
@media only screen and (min-width:901px) and (max-width:1023px){
.left-top-1 , .left-top-2 , .left-top-3{
    padding-left: 0px;
}
.roadmap .w-col.w-col-2 {
    padding: 0px;
}
.right-top-1 , .right-top-2, .right-top-3 {
    padding-right: 0px;
}
img.road-size {
    min-height: 700px;
}
.left-top-3 {
    padding-top: 30%;
}
img.road-size {
    min-height: 700px;
    width: 150px;
    margin: auto;
}
}
@media only screen and (min-width:1024px) and (max-width:1199px){
img.road-size {
    min-width: 172px;
    width: 170px;
    margin: auto;
}
.right-top-1 {
    padding-top: 218px;
}
.left-top-2 {
    padding-top: 150px;
}
.right-top-2 {
    padding-top: 190px;
}
.left-top-3 {
    padding-top: 190px;
}
}
@media only screen and (max-width:991px){
nav.nav-menu.w-nav-menu {
    display: block;
    width: 100%;
    position: absolute;
    top: 55px;
    margin-left: -20px;
    background: #000;
}
nav.nav-menu.w-nav-menu a.w-nav-link {
    display: block;
    padding-bottom: 0px !important;
}
nav.nav-menu.w-nav-menu a:nth-child(1) {
    padding-top: 0px !important;
}
.menu-trigger {
    width: 25px;
    top: 15px;
}
nav.nav-menu.w-nav-menu a:after{
    bottom: 0px !important;
}
}
@media only screen and (max-width:767px){
  .discord-main {
    top: 14vw!important;
}
.on-hover-hide, .hover-show {
    width: 100%;
}
.left-top-1 , .left-top-2 , .left-top-3 , .right-top-1 , .right-top-2 , .right-top-3 {
    margin-top: 0px!important;
    padding-top: 0px!important;
    padding-bottom:20px;
}
.road_position {
     right: 0;
    left: 0px;
    margin: auto;
    bottom: 12px;
    position: absolute;
    max-width: initial;
    width: 241px;
}
.roadmap {
    padding-bottom: 260px!important;
}
.join-the-community {
    padding-top: 120px!important;
}
a.community_btn.w-inline-block {
    margin-right: 0px;
}
.text-block.faq-icon {
    min-width: 45px;
}
.roadmap .w-col {
    padding: 0;
}
.bunny-2 .w-col {
    width: 25%;
}
.bunny-heading .heading-5 .bold-text-2 {
    line-height: initial!important;
    position: relative!important;
    top: 15px!important;
}
.accordion-item-trigger strong {
    line-height: 30px;
}
  .community_flex a img {
    max-width: 300px;
    max-height: 75px;
}
}
@media only screen and (min-width:768px){
.roadmap .w-col-tiny-3 {
    display: none;
}
.roadmap .w-col-tiny-9 {
    width: 100%!important;
}
}
.discord-main {
    width: 280px;
    position: absolute;
    top: 25%;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}
  @media only screen and  (min-width:768px) and  (max-width:992px) {
      .discord-main {
    top: 13vw!important;
} 
  }
  @media only screen and  (max-width:479px) {
      .discord-main {
        top: 22vw!important;
    width: 200px !important;
} 
  }
 @media only screen and  (min-width:768px) and (max-width:991px) {
    .community_flex a img {
    max-height: 80px;
    width: auto;
}
  }
/*@media only screen and  (min-width:992px) {
  .home_community_flex .community_flex a {
    width: 50%;
}
  }*/
.section-3.discord-now-page.wf-section {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}


a.w-nav-link:hover {
    box-shadow:initial !important;
}
nav.nav-menu.w-nav-menu a:after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #60CFFF;
    position: absolute;
    bottom: 15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
nav.nav-menu.w-nav-menu a:hover:after {
    width: 100%;
}
a.w-nav-link {
    padding: 25px 15px;
}
.heading-3.heading-bnny {position: absolute;width: 100vw;}
.w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.container {
    display: contents !important;
}
.bunny-heading {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
}
.bunny-heading .heading-5 .bold-text-2 {
    font-size: 10vw;
    line-height: 123px;
}
.d-none {
    display: none;
}
.on-hover:hover .hover-show {
    display: block!important;
}
.on-hover:hover .on-hover-hide {
    display: none;
}
  .accordion-item-content{display:none;}
  @media only screen and ( max-width:991px){
    nav.nav-menu.w-nav-menu {
    right: -350px;
    max-width: 300px !important;
    transition: all .5s ease;
    display: block !important;
    top: 0;
    padding-top: 40px;
    min-height: 100vw;
    margin: auto;
          z-index: 9;
          position: fixed;
    height: 100%;
}
.open-menu{
  right: 0 !important;
    }
.navbar-collapse .navbar-toggler {
    display: block;
    right: 20px;
    top: 20px;
    position: absolute;
}
    .stick, .stick:after, .stick:before {
    width: 30px;
    height: 2px;
    background: #ffffff;
    position: absolute;
    left: 0;
    top: auto;
    transition: all 0.6s;
    border-radius: 5px;
}
    .stick.open {
    transform: translateX(-50px);
    background: transparent;
}
    .stick:before {
    content: '';
    top: -10px;
    left: 0;
}
    .stick.open:before {
    transform: rotate(
45deg
) translate(42px, -28px);
    left: 2px;
}
    .stick:after {
    content: '';
    top: 10px;
    left: 0;
}
    .stick.open:after {
    transform: rotate(-45deg) translate(42px, 28px);
    left: 2px;
}
    .cross-btn {
    position: absolute;
    top: 0;
    right: 30px;
    top: 10px;
}
    button.navbar-toggler.navbar-toggler-main {
    background: transparent !important;
}
    .navigation {
     min-height: 60px;
}
.logo-text {
    margin-top: 18px !important;
}
.overlay-block:before {
    display: block;
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.65);
    pointer-events: all;
    -webkit-transition: background-color 0.5s linear;
    transition: background-color 0.5s linear;
    z-index: 9;
}
}
